<template>
  <div id="Information">
    <div class="posFix" ref="posFix">
      <div class="titleTop" >
        <div @click="changePath('/StarMix')">星球</div>
        <div @click="changePath('/ActivityCourse?activeName=a')">直播</div>
        <div @click="changePath('/ActivityCourse?activeName=b')">精品课</div>
        <div class="active">保观资料库</div>
        <div @click="changePath('/Inspiration')">想法</div>
      </div>
      <div class="topSearch">
        <van-search
          v-model="search"
          shape="round"
          background="#23252E"
          placeholder="请输入搜索关键词"
          @input = focusChange
          style="flex:1"
        />
        <div class="right" @click="changePath('/MyCollection')">
          <div><van-icon style="font-size:0.16rem;margin:0 0.04rem 0 0.11rem" name="star-o" />我的收藏</div>
        </div>
      </div>
  
      <div class="hotMain" >
        <div class="forth">
          <div>保观资料库</div>
          <div>
            <span @click="show = !show;letters = false" :class="{'white':show}">
              <van-icon v-if="show" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
              综合排序
            </span>
            <span @click="letters = !letters;show=false" :class="{'white':letters}"><img src="../assets/image/findOut/flitGray.png" />筛选</span>
          </div>
        </div>
      </div>
    </div>
    <div class="blackFix" ref="blackFix"></div>
    

    <div>
      <van-popup v-model="letters" position="top" style="width:100%;z-index:5">
        <div class="topPop">
          <div class="titleTab">
            <div :class="{'active':cateId == ''}" @click="cateId = ''">全部</div>
            <div :class="{'active':cateId == i.id}" v-for="(i,item) in getDocCate" :key="item" @click="cateId = i.id">{{i.name}}</div>
          </div>
          <div class="anscontain"><span @click="cateId = ''">重置</span><span @click="checkCateId">完成</span></div>
        </div>
      </van-popup>
      <van-popup v-model="show" position="top" style="width:100%;z-index:5">
        <div class="sort">
          <p :class="{'active':sort == 0}" @click="checkSort(0)">智能排序</p>
          <p :class="{'active':sort == 1}" @click="checkSort(1)">按时间升序</p>
          <p :class="{'active':sort == 2}" @click="checkSort(2)">按时间降序</p>
          <p :class="{'active':sort == 3}" @click="checkSort(3)">按阅读量升序</p>
          <p :class="{'active':sort == 4}" @click="checkSort(4)">按阅读量降序</p>
        </div>
      </van-popup>
    </div>
    <!-- 置顶 -->
    <div class="InfoMain topMost" ref="topMost" >
      <div v-for="(i,item) in getStickFile" :key="item">
        <img  src="../assets/image/findOut/redTop.png" />
        <div class="first">
          <div>
            <div>
              <img src='../assets/image/home/image1.png' />
              <img src='../assets/image/findOut/blueV.png' />
            </div>
            <div>
              <p>
                保观
                <img src='../assets/image/findOut/blueTag.png' />
              </p>
            </div>
          </div>
        </div>
        <div class="fifth" @click="toLink(i.doc_file_info.path,i.doc_file_info.id)">
          <img v-if="i.doc_file_info.type == 1" src="../assets/image/home/doc_type_1.png" />
          <img v-else-if="i.doc_file_info.type == 2" src="../assets/image/home/doc_type_2.png" />
          <img v-else-if="i.doc_file_info.type == 3" src="../assets/image/home/doc_type_3.png" />
          <img v-else-if="i.doc_file_info.type == 4" src="../assets/image/home/doc_type_4.png" />
          <img v-else-if="i.doc_file_info.type == 5" src="../assets/image/home/doc_type_5.png" />
          <div>
            <p>{{i.doc_file_info.name}}</p>
            <p>{{i.doc_file_info.ext}} {{i.doc_file_info.size}}</p>
          </div>
        </div>
        <div class="forth">
          <div>
            <span>{{Global.clearWhite($moment(new Date(Date.parse((i.publish_at).replace(/-/g, '/')))).fromNow())}}</span>
            <span class="but">{{i.category.name}}</span>
          </div>
          <div>
            <span>{{i.upvote_count}}
              <van-icon @click.stop="noLike(i.id,item,'top')" v-if="i.upvote.length" name="good-job" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
              <van-icon @click.stop="toLike(i.id,item,'top')" v-else name="good-job-o" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
            </span>

            <span>
              <van-icon @click.stop="noStar(i.id)" v-if="getCollectIds.indexOf(i.id) != '-1'" name="star" size=".16rem" color="#E59813" />
              <van-icon @click.stop="toStar(i.id)" v-else name="star-o" size=".16rem" color="#E59813" />
            </span>
            
          </div>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="InfoMain bottomMost"
    >
      <div v-for="(i,item) in getDocList" :key="item">
        <div class="first">
          <div>
            <div>
              <img src='../assets/image/home/image1.png' />
              <img src='../assets/image/findOut/blueV.png' />
            </div>
            <div>
              <p>
                保观
                <img src='../assets/image/findOut/blueTag.png' />
              </p>
            </div>
          </div>
        </div>
        <div class="fifth" @click="toLink(i.doc_file_info.path,i.doc_file_info.id)">
          <img v-if="i.doc_file_info.type == 1" src="../assets/image/home/doc_type_1.png" />
          <img v-else-if="i.doc_file_info.type == 2" src="../assets/image/home/doc_type_2.png" />
          <img v-else-if="i.doc_file_info.type == 3" src="../assets/image/home/doc_type_3.png" />
          <img v-else-if="i.doc_file_info.type == 4" src="../assets/image/home/doc_type_4.png" />
          <img v-else-if="i.doc_file_info.type == 5" src="../assets/image/home/doc_type_5.png" />
          <div>
            <p>{{i.doc_file_info.name}}</p>
            <p>{{i.doc_file_info.ext}} {{i.doc_file_info.size}}</p>
          </div>
        </div>
        
        <div class="forth">
          <div>
            <span>{{Global.clearWhite($moment(new Date(Date.parse((i.publish_at).replace(/-/g, '/')))).fromNow())}}</span>
            <span v-if="i.is_file == 1" class="but">{{i.category.name}}</span>
          </div>
          <div>
            <span>{{i.upvote_count}}
              <van-icon @click.stop="noLike(i.id,item)" v-if="i.upvote && i.upvote.length" name="good-job" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
              <van-icon @click.stop="toLike(i.id,item)" v-else name="good-job-o" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
            </span>
            <span>
              <van-icon @click.stop="noStar(i.id)" v-if="getCollectIds.indexOf(i.id) != '-1'" name="star" size=".16rem" color="#E59813" />
              <van-icon @click.stop="toStar(i.id)" v-else name="star-o" size=".16rem" color="#E59813" />
            </span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import start from '../assets/image/findOut/start.png'
import startn from '../assets/image/findOut/startn.png'
export default {
  name: "Information",
  data(){
    return{
      show:false,
      letters:false,
      getDocCate:[],//文件分类
      getStickFile:[],//指定文章
      getDocList:[],//文件列表
      finished:false,
      loading:true,
      next_page_url:'',
      sort:0,
      cateId:'',
      value:'',
      getCollectIds:[],//收藏的文件id
      search:'',
      isVip:'',
      is_approve:'',
      isActivate: ''
    }
  },
  created(){
    this.Global.getShareConfig({
      title: '保观资料库  | 保观大咖会',
      desc: '幸运的是，今天的我们不用担心过于专注而被野兽吃掉；不幸的是，知识爆炸的今天我们必须学会过滤信息。'
    },this)
  },
  mounted() {
    this.$refs.blackFix.style.top = this.$refs.posFix.offsetHeight + 'px'
    this.$refs.topMost.style.paddingTop = this.$refs.posFix.offsetHeight + this.$refs.blackFix.offsetHeight+'px'

    // 文件类型
    this.axios({
      method: 'GET',
      url: '/doc/getDocCate',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocCate = res.data.data;
      }
    })
    // 置顶文件
    this.axios({
      method: 'GET',
      url: '/doc/getStickFile',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0 && res.data.data){
        this.getStickFile = res.data.data.list;
      }
    })
     // 用户收藏文件id
    this.axios({
      method: 'GET',
      url: 'doc/getCollectIds',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getCollectIds = res.data.data
      }
    })
    // 文件列表
    this.axios({
      method: 'GET',
      url: '/doc/getDocList?sort=0',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.isVip = res.data.data.status
        this.isActivate = res.data.data.isActivate
        this.loading = false
        this.getDocList = res.data.data.list.data;
        this.next_page_url = res.data.data.list.next_page_url
      }
    })
  },
  methods:{
    // 输入变更触发函数
    focusChange(){
      window.scrollTo(0,0)
      this.axios({
        method: 'GET',
        url: '/doc/searchDoc?sort='+this.sort+'&cateId='+this.cateId+'&search='+this.search,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            // console.log(res.data.data.list)
            this.getDocList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
            // console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    toLink(link,id){
      if(this.isVip == 2 || (this.isVip == 8 && this.isActivate == 1)){
        this.axios({
          method: 'PATCH',
          url: '/doc/addDocViewCount/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(() => {
          window.location.href = link
        }) 
      }else{
        let msg = '';

        if (this.isVip == 8) {
          msg = '请先激活会员'
        } else {
          msg = '请先购买或激活会员'
        }
        this.$toast(msg)
        var _this = this;
        setTimeout(function(){
          if (_this.isVip == 8) {
            _this.$router.push('/EditMessage')
          } else {
            _this.$router.push('/MemberProduct')
          }
          
        },2000)
      }
      // MemberProduct
    },
    changePath(path){
      this.path = path
      this.$router.push(path)
    },
    checkSort(a){
      this.show = false
      this.getDocList = []
      this.sort = a
      this.firstLoad()
    },
    checkCateId(){
      this.letters = false
      this.getDocList = []
      this.sort = 0
      this.next_page_url = ''
      this.firstLoad()
    },
    firstLoad(){
      window.scrollTo(0,0)
      this.axios({
        method: 'GET',
        url: '/doc/getDocList?sort='+this.sort+'&cateId='+this.cateId,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            console.log(res.data.data.list)
            this.getDocList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    // 下滑加载
    onLoad(){
      if(this.next_page_url){
        var url;
        if(this.search){
          url = this.next_page_url+'&sort='+this.sort+'&cateId='+this.cateId+'&search='+this.search
        }else{
          url = this.next_page_url+'&sort='+this.sort+'&cateId='+this.cateId
        }
        // console.log(this.search,url)

        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.list.data)
              this.next_page_url = res.data.data.list.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    toStar(docId){
      this.axios({
        method: 'PATCH',
        url: '/doc/collectFile',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getCollectIds.push(docId)
          this.$toast({
            message: '收藏成功',
            icon: start,
          })
        }
      })
    },
    noStar(docId){
      this.axios({
          method: 'PATCH',
          url: '/doc/collectFile?sort='+this.sort+'&docId='+docId,
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            var index = this.getCollectIds.indexOf(docId);
            this.getCollectIds.splice(index, 1);
            this.$toast({
              message: '取消收藏',
              icon: startn,
            })
          }
        })
    },  
    toLike(docId,index,top){
      this.axios({
        method: 'POST',
        url: '/doc/upvoteDoc',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          if(top){
            this.getStickFile[index].upvote.push([{id:docId}])
            this.getStickFile[index].upvote_count++
          }else{
            this.getDocList[index].upvote.push([{id:docId}])
            this.getDocList[index].upvote_count++
          }
          this.$toast({
            message: '点赞成功',
            icon: start,
          })
        }
      })
    },
    noLike(docId,index,top){
      this.axios({
          method: 'POST',
          url: 'doc/upvoteDoc',
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            // var index = this.getCollectIds.indexOf(docId);
            if(top){
              this.getStickFile[index].upvote = []
              this.getStickFile[index].upvote_count--
            }else{
              this.getDocList[index].upvote = []
              this.getDocList[index].upvote_count--
            }
            this.$toast({
              message: '取消点赞',
              icon: startn,
            })
          }
        })
    },  
  }
};
</script>
<style lang="less" scoped>
#Information {
  .titleTop {
    background: #23252E;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      height: 3px;
      background: #3890ff;
      left: 0;
      right: 0;
      margin: auto 0.06rem;
      bottom: -0.05rem;
      border-radius: 3px;
    }
  }
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 750px;
    background: #16171D;
    z-index: 10;
    >div{
      background: #23252E;
    }
  }
  .blackFix{
    z-index: 3;
    background: #16171D;
    height: 0.11rem;
    position: fixed;
    width: 100%;
    max-width: 750px;
  }
  .bottomMost{
    padding-bottom: 0.6rem;
    margin-top: 0.11rem;
  }
  .InfoMain{
    padding: 0;
    >div:last-child{
      border-bottom: none;
    }
    >div{
      padding: 0.18rem 0.15rem;
      position: relative;
      border-bottom: .1px solid #5C5D6E61;
      >img{
        position:absolute;
        top: 0;
        width: 0.36rem;
        left: 0;
      }
      
      .fifth{
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-start;
        margin: -0.12rem 0 0.12rem 0.47rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        margin-left: 0.47rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.1rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.14rem;
          }
        }
        >div:first-child{
          display: flex;
          >span:first-child{
            color: #989AB1;
            font-size: 0.14rem;
            margin: 0 0.04rem 0 0;
          }
          >span.but{
            color: #9091A3;
            padding: 0 0.05rem;
            background: #3D404F;
            border-radius: 0.02rem;
            margin-right: 0.04rem;
            font-size: 0.14rem;
          }
        }
      }
      .first{
        >div{
          flex: 1;
          color: #989AB1;
          display: flex;
          font-size: .16rem;
          // align-items: center;
          >div:last-child{
            flex: 1;
            margin-top: -0.02rem;
            >p{
              margin: 0 0.04rem 0 0;
              margin-left: 0.1rem;
              display: flex;
              font-weight: bold;
              >img{
                height: .14rem;
                margin-top: .05rem;
                margin-left: .06rem;
              }
            }
          }
          >div:first-child{
            position: relative;
            height: 0.37rem;
            >img:first-child{
              width: 0.37rem;
              height: 0.37rem;
              border-radius: 50%;
            }
            >img:last-child{
              position: absolute;
              width: 0.16rem;
              bottom: -0.03rem;
              right: -0.0rem;
            }
          }
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .topSearch{
    display: flex;
    padding: 0.15rem;
    .right{
      display: flex;
      align-items: center;
      color: #9091A3;
      font-size: 0.14rem;
    }
  }
  .sort{
    padding: 0.15rem 0.3rem 0.05rem;
    >p{
      color: #989AB1;
      font-size: 0.14rem;
      margin-bottom: 0.12rem;
    }
    .active{
      color: #3890FF;
    }
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    display: flex;
    flex-wrap: wrap;
    padding: 0.15rem 0.15rem 0.1rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  
  .personalContent{
    margin-top: 0.1rem;
  }
  .hotMain{
    .forth{
      display: flex;
      justify-content: space-between;
      padding:0 0.15rem 0.15rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            >img{
              width: 0.12rem;
              margin:0.02rem 0.05rem 0 0.13rem;
            }
          }
          .white{
            color: #F6F7FD;
          }
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.16rem;
        }
      }
  }
  >div{
    background: #23252E;
  }
}
</style>